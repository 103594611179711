.cont {
  @apply pt-12 pb-4 grid justify-items-center;
}

.body {
  @apply max-w-5xl space-x-4 bg-main-dark flex flex-row p-4;
}

.paragraph {
  @apply whitespace-pre-wrap space-y-2 flex flex-1 flex-col text-gray-200;
}

p, li, span {
  @apply text-neutral-white;
}

.head {
  @apply text-2xl font-bold text-main-yellow uppercase;
}

.divider {
  @apply divide-y-2 divide-gray-500 m-2 text-center;
}

.cDivider {
  width: 700px;
  max-width: 100%;
}

.input {
  @apply placeholder-gray-100 placeholder-opacity-40 text-neutral-white focus:outline-none bg-main-dark w-1/2;
  caret-color: #E9E9ED;
}

.inputgap {
  @apply max-w-5xl bg-main-dark;
}

.divider {
  @apply divide-y divide-gray-500 text-center;
}

.textarea {
  @apply w-full placeholder-gray-100 placeholder-opacity-40 text-neutral-white bg-main-dark focus:outline-none;
  caret-color: #E9E9ED;
}

@media screen and (max-width: 640px) {
  .form{
    @apply w-full;
  }
  .cDivider {
    @apply w-full;
  }
  .input {
    @apply w-full leading-10;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-serif;
}
